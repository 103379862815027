.ourcommit-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background-color: #f0f0f08b;
    /* gap: 88px; */
}

.flex-container {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 5rem;
    max-width: 900px;
    width: 100%;
}

.flex-container>* {
    width: 50%;
}

.top-sub-heading {
    font-weight: 600;
    color: #479a7e;
    font-size: var(--top-sub-heading);
}

.flex-container img {
    width: 100%;
}

.main-heading {
    display: flex;
    align-items: center;
    justify-content: center;
}


.main-heading h3 {
    text-align: center;
}

.ourcommit-items {
    display: flex;
    flex-direction: column;
    gap: 16px;
    list-style: none;
}

.ourcommit-items li svg {
    display: block;
    flex: none;
    height: 32px;
    overflow: hidden;
    position: relative;
    width: 24px;
}

.ourcommit-items li {
    display: flex;
    gap: 5px;
    border: 1px solid #ccc;
    border-radius: 10px;
    padding: 15px;
    font-size: 18px;
    font-weight: 500;
    background-color: #fff;
    color: rgb(26, 28, 30);
}




@media screen and (max-width:900px) {
    .main-heading {
        margin-bottom: 2rem;
    }

    .flex-container>* {
        width: 100%;
    }
}

@media screen and (max-width:600px) {

    .main-heading h3 {
        text-align: center;
    }

    .flex-container {
        flex-direction: column;
        padding: 0 1rem;
        gap: 0;
    }
}