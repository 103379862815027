.mySwiper {
  width: 100%;
  height: 70%;
  background-color: #f6f7f9;
}

.swiper-slide {
  display: flex;
  text-align: center;
  justify-content: center;
  align-items: center;  
  flex-direction: column;
  /* height: 300px; */
  /* padding-left: -200px; */
}

.swiper-pagination-bullet {
  border: 2px solid black;
  opacity: 0.6;
  background: none;
  width: 6px;
  height: 6px;
}

.swiper-pagination-bullet-active {
  border: none;
  background: grey;
  width: 12px;
  height: 12px;
}
/* 
.swiper-slide.active {
  opacity: 1;
} */

/* .swiper-slide.inactive {
  opacity: 0.5;
} */
/* .inactive-slide {
  opacity: 0.5;
} */

.image-svg-1 {
  height: 50px;
  width: 50px;
}

.image-svg-2 {
  height: 70px;
  width: 70px;
}

.income-small-card {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 18rem;
  height: 15rem;
  box-shadow: 0px 10px 20px 2px rgba(198, 198, 198, 1.5);
  border: 1px rgba(198, 198, 198, 0.3);
  border-radius: 10px;
  text-align: left;
  margin: 3% 5%;
  padding-bottom: 5%;
}

.income-small-card img {
  height: auto;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
  margin: 20px;
}

.income-small-card h1 {
  font-size: 25px;
  font-family: 'Gilroy-Bold';
}

.income-small-card p {
  font-family: 'Gilroy-Medium';
  font-size: 14px;
}
