/* Basic styling */
body {
    font-family: Arial, sans-serif;
  }
  
  /* Button to open the popup */
  .open-popup-button {
    padding: 30px 40px;
    font-size: 16px;
    cursor: pointer;
  }
  .iconImage {
    height: 70px;
    /* width: 50px; */
  }
  
  /* Blurred background */
  .popup-background {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.5);
    backdrop-filter: blur(5px);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 9999;
  }
  
  /* Popup dialog */
  .popup-dialog {
    background: white;
    padding: 20px;
    border-radius: 8px;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
    max-width: 90%;
    width: 400px;
    margin-left: 10px;
    margin-right: 10px;
    text-align: center;
    position: relative;
  }
  
  /* Close button */
  .close-popup-button {
    position: absolute;
    top: 10px;
    right: 10px;
    background: none;
    color: black;
    border: none;
    font-size: 24px;
    cursor: pointer;
  }
  
  /* Choice buttons */
  .choice-buttons {
    display: flex;
    justify-content: space-around;
    margin-top: 20px;
  }
  .choice-button {
    padding: 10px 20px;
    font-size: 16px;
    background: #0170dc;
    color: white;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    width: 100px;
  }
  