.account-container {
  display: flex;
  align-items: center;
  padding: 0%;
}

.circle {
  border-radius: 50%;
  margin-right: 10px;
  padding: 5px;
  background-color: lightgray;
}

.nested-list {
  overflow: hidden;
}