/* Body.css */

.body-wrapper { 
  display: flex;
  flex-direction: column;
  font-family: 'Raleway', sans-serif;
  background-color: #fff;
  position: relative;
  width: 100%;
  height: 100%;
}

.body-wrapper .venq-container {
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  width: 100%;
  height: 40vw;
}

.body-wrapper .text-overflow {
  overflow: hidden;
}

.body-wrapper .venq-text {
  display: flex;
  font-size: 9rem; /* Base font size */
  font-weight: normal;
}

/* Individual letter styling */
.body-wrapper .venq-letter {
  display: inline-block;
}

/* Responsive styling */
@media (max-width: 1200px) {
  .body-wrapper .venq-container {
    height: 45vw;
  }
  .body-wrapper .venq-text {
    font-size: 8rem;
  }
}

@media (max-width: 1024px) {
  .body-wrapper .venq-container {
    height: 50vw;
  }
  .body-wrapper .venq-text {
    font-size: 7rem;
  }
}

@media (max-width: 768px) {
  .body-wrapper .venq-container {
    height: 60vw;
  }
  .body-wrapper .venq-text {
    font-size: 6rem;
  }
}

@media (max-width: 600px) {
  .body-wrapper .venq-container {
    height: 70vw;
  }
  .body-wrapper .venq-text {
    font-size: 5rem;
  }
}

@media (max-width: 480px) {
  .body-wrapper .venq-container {
    height: 160vw;
  }
  .body-wrapper .venq-text {
    font-size: 4rem;
  }
}

.body-wrapper .black-bg {
  width: 100%;
  height: 43vw;
  padding-top: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 10;
}

.body-wrapper .black-bg-inner {
  width: 90%;
  height: 100%;
  margin-top: 10vw;
  border-radius: 1.5rem;
  background-color: black;
}

/* Responsive adjustments for black background */
@media (max-width: 1200px) {
  .body-wrapper .black-bg {
    height: 50vw;
  }
  .body-wrapper .black-bg-inner {
    margin-top: 8vw;
  }
}

@media (max-width: 1024px) {
  .body-wrapper .black-bg {
    height: 60vw;
  }
  .body-wrapper .black-bg-inner {
    margin-top: 6vw;
  }
}

@media (max-width: 768px) {
  .body-wrapper .black-bg {
    height: 60vw;
  }
  .body-wrapper .black-bg-inner {
    margin-top: 5vw;
  }
}

@media (max-width: 600px) {
  .body-wrapper .black-bg {
    height: 70vw;
  }
  .body-wrapper .black-bg-inner {
    margin-top: 4vw;
  }
}

@media (max-width: 480px) {
  .body-wrapper .black-bg {
    height: 140vw;
  }
  .body-wrapper .black-bg-inner {
    margin-top: 3vw;
  }
}
