/* CSS file */
#hover {
    transition: background-color 0.3s, color 0.3s;
}

#hover:hover {
    background-color: #fff; /* Darker shade for hover effect */
    color: black;
}
.mask-image {
    mask-image: linear-gradient(to bottom, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0) 50%);
    -webkit-mask-image: linear-gradient(to bottom, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 1) 50%); /* for Safari */
}

  