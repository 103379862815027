/* Base Styles */
.LinkContainer {
  display: flex;
  justify-content: space-evenly;
  width: 350px;
  font-family: "Gilroy-Bold";
}

.buttonContainer {
  display: flex;
  gap: 20px;
}

/* Button Styles */
.loginButton {
  border: 2px solid #0170dc;
  border-radius: 8px;
  color: #0170dc;
  font-size: 20px;
  font-weight: 700;
  line-height: 24px;
  text-transform: none;
  background: transparent;
  padding: 5px 20px;
  cursor: pointer;
  font-family: "Inter";
  transition: all 0.2s ease;
}

.loginButton a {
  text-decoration: none;
  font-weight: bold;
  font-size: 20px;
  line-height: 24px;
  color: #0170dc;
}

.loginButton:hover {
  background-color: rgba(238, 238, 238, 0.4);
  transform: scale(1.05);
  transition-duration: 200ms;
}

.SignupButton {
  border: 2px solid #0170dc;
  border-radius: 8px;
  background-color: #0170dc;
  color: white;
  text-transform: none;
  padding: 5px 20px;
  cursor: pointer;
  text-decoration: none;
  font-style: normal;
  font-weight: 700;
  font-size: 20px;
  line-height: 24px;
  font-family: "Inter";
  transition: all 0.2s ease;
}

.SignupButton:hover {
  background-color: #186baa;
  transform: scale(1.05);
  border: 2px solid #186baa;
  transition-duration: 200ms;
}

.SignupButton a {
  text-decoration: none;
  color: #fff;
  font-style: normal;
  font-weight: 700;
  font-size: 20px;
  line-height: 25px;
  font-family: "Inter";
}

/* Menu Icon Styles */
.menu_icon {
  color: black;
  cursor: pointer;
  display: none;
}

.menu_icon > svg {
  color: black;
  width: 24px;
  height: 24px;
}

/* Dropdown Menu */
.dropDown {
  display: flex;
  flex-direction: column;
}

/* Mobile Navigation */
.mobileNavItems {
  font-family: "Inter";
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  position: fixed;
  top: 64px; /* Height of navbar */
  left: 0;
  width: 100%;
  height: calc(100vh - 64px);
  background-color: #15242f;
  color: #fff;
  border-top: 1px solid rgba(255, 255, 255, 0.1);
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  z-index: 1000;
  padding-top: 1rem;
  animation: slideIn 0.3s ease-out;
  overflow-y: auto;
}

@keyframes slideIn {
  from {
    transform: translateX(100%);
  }
  to {
    transform: translateX(0);
  }
}

.mobileNavItems .loginButton {
  width: 86%;
  margin: 0 7%;
  text-align: center;
}

.mobileNavItems .loginButton a {
  color: #0170dc;
}

.mobileNavItems .SignupButton {
  width: 86%;
  margin: 0 7%;
  text-align: center;
}

.mobileNavItems .buttonContainer {
  padding: 1rem 0;
  flex-direction: column;
  gap: 1rem;
  width: 100%;
}

.mobileItem {
  padding: 1rem 7%;
  font-size: 1.25rem;
  color: white;
  transition: background-color 0.3s ease;
  border-bottom: 1px solid rgba(255, 255, 255, 0.1);
}

.mobileItem:hover {
  background-color: rgba(255, 255, 255, 0.1);
}

/* Account Container */
.account-container {
  display: flex;
  align-items: center;
  gap: 1rem;
  padding: 0.5rem;
}

.dashboard-mobile-screen {
  width: 86%;
  margin: 1rem 7%;
  padding: 0.5rem;
  border-radius: 25px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #0170dc;
  color: white;
  border: none;
  font-family: "Inter";
  font-weight: 600;
}

/* Responsive Breakpoints */
@media (max-width: 1024px) {
  .LinkContainer {
    width: 300px;
  }

  .loginButton,
  .SignupButton {
    font-size: 16px;
    padding: 5px 15px;
  }
}

@media (max-width: 768px) {
  .menu_icon {
    display: block;
  }

  .imageContainer {
    padding-inline: 1rem;
  }

  .imageContainer img {
    width: 100px;
    height: auto;
  }

  .LinkContainer {
    width: 260px;
  }

  .buttonContainer {
    gap: 10px;
  }

  .loginButton,
  .SignupButton {
    padding: 3px 15px;
    font-size: 15px;
  }

  .loginButton a,
  .SignupButton a {
    font-size: 15px;
  }
}

@media (max-width: 480px) {
  .imageContainer img {
    width: 80px;
  }

  .mobileNavItems {
    top: 56px;
    height: calc(100vh - 56px);
  }

  .mobileItem {
    font-size: 1.1rem;
    padding: 0.875rem 7%;
  }

  .loginButton,
  .SignupButton {
    padding: 3px 12px;
    font-size: 14px;
  }

  .account-container {
    padding: 0.5rem 7%;
    width: 100% !important;
  }
}

/* Additional Mobile Styles */
.mobile-menu-container {
  position: relative;
}

.account-container.mobile {
  padding: 1rem 7%;
  width: 100% !important;
  border-top: 1px solid rgba(255, 255, 255, 0.1);
}

/* Navigation Link Styles */
.LinkContainer a,
.LinkContainer button {
  text-decoration: none;
  color: inherit;
  background: none;
  border: none;
  cursor: pointer;
  font-family: inherit;
  padding: 0;
  margin: 0;
}

/* Menu Dropdown Styles */
.menu-dropdown {
  position: absolute;
  top: 100%;
  right: 0;
  background: white;
  border-radius: 8px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  z-index: 1000;
  min-width: 200px;
}

/* Ensure proper spacing for menu items */
.MuiMenuItem-root {
  padding: 0.75rem 1rem !important;
}

/* Proper image sizing in menu items */
.MuiMenuItem-root img {
  width: 20px;
  height: 20px;
  margin-right: 0.5rem;
}

/* Animation for menu transitions */
@keyframes fadeIn {
  from {
    opacity: 0;
    transform: translateY(-10px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

.menu-content {
  animation: fadeIn 0.2s ease-out;
}