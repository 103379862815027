.text-youtube-container {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 2rem;
    margin: 0 5rem;
    position: relative;
    color: #444444;
}

.text-youtube-container .active {
    box-shadow: 1px 2px 3px 1px rgba(0, 0, 0, .2);
}



.youtube-container {
    padding-top: 1em;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
}

.text-youtube-container>div {
    width: 50%;
}

.text-youtube-right {
    width: 30%;
}

.text-youtube-container iframe {
    width: 90%;
    height: auto;
    aspect-ratio: 16 / 9;
}

.btn-container {
    margin-top: 3rem;
    display: flex;
    flex-direction: column;
}

.btn-container input {
    display: none;
}

.btn-container label:hover {
    box-shadow: 1px 2px 3px 1px rgba(0, 0, 0, .2);
}

.btn-container label {
    display: flex;
    align-content: center;
    align-items: center;
    border: 1px solid #f0f0f2;
    padding: 16px 16px 16px 24px;
    margin: 4px 0;
    border-radius: 16px;
    cursor: pointer;
}

.text-youtube-container .heading {
    font-weight: 600;
    font-size: 64px;
    line-height: 64px;
}

.text-youtube-container .sub-heading-home {
    font-size: 1.12rem;
    line-height: 1.5625rem;
    margin-top: 24px;
    color: #7c7e83;
    font-weight: 438;
    width: 80%;
}



.text-youtube-right {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}


@media screen and (max-width:750px) {
    .text-youtube-container {
        margin: 0 1rem;
        flex-direction: column;
    }

    .text-youtube-container>div {
        width: 100%;
    }
}