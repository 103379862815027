.bHKinAspenFrame,
.depth10Frame0 {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}
.depth10Frame0 {
  width: 19.5px;
  height: 19.5px;
  display: none;
}
.depth9Frame0 {
  width: 24px;
  height: 24px;
  position: relative;
  overflow: hidden;
  flex-shrink: 0;
}
.depth8Frame0 {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
}
.overview {
    position: relative;
    line-height: 21px;
    font-weight: 500;
  }
  .depth7Frame0,
  .depth8Frame1,
  .depth9Frame01 {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
  }
  .depth7Frame0 {
    align-self: stretch;
    flex-direction: row;
    align-items: center;
    padding: var(--padding-5xs) var(--padding-xs);
    gap: var(--gap-xs);
  }
  .depth10Frame01,
  .vector0 {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }
  .depth10Frame01 {
    width: 18px;
    height: 18.7px;
    display: none;
  }
  .depth9Frame02 {
    width: 24px;
    height: 24px;
    position: relative;
    overflow: hidden;
    flex-shrink: 0;
  }
  .depth8Frame01 {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
  }
  .listings {
    position: relative;
    line-height: 21px;
    font-weight: 500;
  }
  depth7Frame1,
  .depth8Frame11,
  .depth9Frame03 {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
  }
  .depth7Frame1 {
    align-self: stretch;
    flex-direction: row;
    align-items: center;
    padding: var(--padding-5xs) var(--padding-xs);
    gap: var(--gap-xs);
  }
  .depth10Frame02,
  .vector01 {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }
  depth10Frame02 {
    width: 13.5px;
    height: 21px;
    display: none;
  }
  .depth9Frame04 {
    width: 24px;
    height: 24px;
    position: relative;
    overflow: hidden;
    flex-shrink: 0;
  }
  .depth8Frame02 {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
  }
  .investors {
    position: relative;
    line-height: 21px;
    font-weight: 500;
  }
  .depth9Frame05 {
    align-self: stretch;
    flex-direction: column;
    align-items: flex-start;
  }
  depth7Frame2,
  .depth8Frame12,
  .depth9Frame05 {
    display: flex;
    justify-content: flex-start;
  }
  .depth8Frame12 {
    width: 85px;
    flex-direction: column;
    align-items: flex-start;
    padding: 0 1px 0 0;
    box-sizing: border-box;
  }
  .depth7Frame2 {
    align-self: stretch;
    flex-direction: row;
    align-items: center;
    padding: var(--padding-5xs) var(--padding-xs);
    gap: var(--gap-xs);
  }
  .depth10Frame03,
  .vector02 {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }
  .depth10Frame03 {
    width: 19.5px;
    height: 18px;
    display: none;
  }
  .depth9Frame06 {
    width: 24px;
    height: 24px;
    position: relative;
    overflow: hidden;
    flex-shrink: 0;
  }
  .depth8Frame03,
  .depth8Frame13 {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
  }
  .depth8Frame13 {
    width: 88.4px;
    border: 0;
    outline: 0;
    background-color: transparent;
    height: 21px;
    font-family: var(--font-work-sans);
    font-weight: 500;
    font-size: var(--font-size-sm);
    color: var(--color-gray);
  }
  depth5Frame0,
  .depth6Frame1,
  .depth7Frame3 {
    align-self: stretch;
    display: flex;
    justify-content: flex-start;
  }
  .depth7Frame3 {
    border-radius: var(--br-xs);
    background-color: var(--color-whitesmoke);
    flex-direction: row;
    align-items: center;
    padding: var(--padding-5xs) var(--padding-xs);
    gap: var(--gap-xs);
  }
  .depth5Frame0,
  .depth6Frame1 {
    flex-direction: column;
    align-items: flex-start;
  }
  .depth6Frame1 {
    height: 232px;
    gap: 8px;
  }
  .depth5Frame0 {
    height: 248px;
  }
  .new {
    position: relative;
    font-size: var(--font-size-sm);
    letter-spacing: 0.21px;
    line-height: 21px;
    font-family: var(--font-work-sans);
    color: var(--color-white);
    text-align: left;
  }
  .depth6Frame0,
  .depth7Frame01,
  .depth8Frame04 {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
  }
  .depth6Frame0,
  .depth7Frame01 {
    background-color: var(--color-dodgerblue);
    overflow: hidden;
  }
  .depth6Frame0 {
    cursor: pointer;
    border: 0;
    padding: 0 var(--padding-base);
    align-self: stretch;
    height: 40px;
    border-radius: var(--br-xs);
    flex-shrink: 0;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    box-sizing: border-box;
  }
  .depth4Frame0,
  .depth5Frame1 {
    align-self: stretch;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
  }
  .depth5Frame1 {
    height: 56px;
    justify-content: flex-start;
  }
  .depth4Frame0 {
    flex: 1;
    background-color: var(--color-white);
    justify-content: space-between;
    padding: var(--padding-base);
  }
  .adminPanel,
  .depth3Frame0,
  .rootFrameWrapper {
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
  }
  .depth3Frame0 {
    height: 760px;
    width: 307px;
    flex-direction: column;
  }
  .adminPanel,
  .rootFrameWrapper {
    box-sizing: border-box;
  }
  .rootFrameWrapper {
    flex: 1;
    flex-direction: column;
    padding: var(--padding-base) 0 0;
    max-width: calc(100% - 311px);
  }
  adminPanel {
    width: 100%;
    position: relative;
    background-color: pink;
    flex-direction: row;
    padding: 20px 24px;
    gap: 4px;
    letter-spacing: normal;
    text-align: left;
    font-size: var(--font-size-sm);
    color: var(--color-gray);
    font-family: var(--font-work-sans);
  }
  @media screen and (max-width: 825px) {
    .depth3Frame0 {
      display:none;
    }    
}              