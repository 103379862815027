.new-testimonials-section {
  display: flex;
  justify-content: space-around;
  gap: 0;
  padding: 20px;
  margin-top: 50px;
}

.testimonial-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 0;
  height: auto;
}

.new-testimonial {
  flex: 1;
  padding: 20px;
  border: 1px solid #ccc;
  border-radius: 10px;
  text-align: center;
  background-color: #f8f8f8;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  position: relative;
  z-index: 1;
  width: 80%;
  max-width: 600px;
}

.new-testimonial.center {
  position: relative;
}

.new-testimonial.large {
  z-index: 2;
  margin-left: 50px;
  margin-right: 50px;
  background-color: #7caccaf5;
  height: 300px;
  top: -50px;
}

.new-testimonial.small.right {
  right: 20%;
  height: 300px;
}

.new-testimonial.small.left {
  left: 25%;
  height: 300px;
}

.new-pic {
  position: absolute;
  bottom: -30px;
  left: 50%;
  transform: translateX(-50%);
  width: 60px;
  height: 60px;
  border-radius: 50%;
  overflow: hidden;
}

.new-pic img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.new-quote {
  align-items: center;
  width: 70%;
  margin-left: 70px;
  font-family: sans-serif;
}

.new-quoteCenter {
  align-items: center;
  font-family: sans-serif;
  width: 70%;
  margin-left: 70px;
}

.new-user {
  margin-top: 10px;
  font-weight: bold;
}

.user-infoleft {
  text-align: center;
  margin-top: 30px;
  margin-left: 50%;
}

.user-infoCenter {
  text-align: center;
  position: relative;
  margin-top: 20px;
  bottom: 40px;
}

.user-inforight {
  text-align: center;
  margin-top: 20px;
  margin-right: 39%;
}

.user-name {
  font-size: 18px;
  font-weight: 100;
  margin-top: 5px;
  margin-bottom: 0px;
  font-family: sans-serif;
}

.user-location {
  font-size: 14px;
  color: #666;
  margin-top: 5px;
  font-family: sans-serif;
}

.new-content {
  text-align: center;
  padding-top: 20px;
  font-family: sans-serif;
  color: #000000;
}

.new-heading-two{
  font-family: Bebas Neue;
  font-size: 1.2rem;
  font-weight: 400;
  text-align: center;
  margin-bottom: 40px;
}
.new-heading {
  font-family: Bebas Neue;
    font-size: 3.5em;;
    font-weight: 400;
    line-height: 77px;
    text-align: center;
}

.new-test-container {
  background-color: #eeeef0;
  height: auto;
}

.left-quote {
  width: 70px;
  height: 70px;
  color: #f1f1f7;
  margin-top: 0px;
}

.side-quote {
  width: 70px;
  height: 70px;
  color: #cacaca;
  margin-top: 0px;
}

.button-clicked {
  height: 50px;
  width: 50px;
  margin-top: 100px;
}

@media screen and (max-width: 600px) {
  /* Add more responsive adjustments as needed */
  .new-testimonial.small.left {
    left: 0%;
    height: 300px;
    background-color: #7caccaf5;
  }

  .new-pic {
    position: absolute;
    bottom: -30px;
    left: 50%;
    transform: translateX(-50%);
    width: 60px;
    height: 60px;
    border-radius: 50%;
    overflow: hidden;
  }

  .new-quote {
    align-items: center;
    width: 100%;
    margin-left: 0px;
    font-family: sans-serif;
    text-align: center;
    justify-content: center;
  }

  .user-infoleft {
    text-align: center;
    margin-top: 30px;
    margin-left: 0%;
  }

  .testimonial-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 0;
    height: 35vh;
  }

  .new-heading {
    font-family: Bebas Neue;
    font-size: 3.5em;;
    font-weight: 400;
    line-height: 77px;
    letter-spacing: 0.05em;
    text-align: center;
  }
}
