.rotated-button-container {
  text-align: center;
  padding: 25px;
  /* transform: rotate(5deg); */
  background-color: transparent;
  margin-bottom: -100px;
  height: 200px;
  position: relative;
  z-index: 0;
}

.rotated-button-container > h3 {
  font-weight: 800;
}

.rotated-heading {
  font-size: 40px;
  margin-bottom: 10px;
  /* transform: rotate(-5deg); */
  margin-top: 50px;
  font-family: "Roboto", "Helvetica", "Arial", sans-serif;
  letter-spacing: 5px;
  font-weight: 400;
}

.rotated-buttons-container {
  display: flex;
  justify-content: center;
  gap: 10px;
  /* transform: rotate(-5deg); */
  margin-top: 20px;
}

.rotated-invest-button,
.rotated-sell-button {
  padding: 10px 10px;
  font-size: 18px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
}

.rotated-invest-button {
  background-color: transparent;
  border: 1px solid #30475e;
  color: #000000;
  font-weight: bold;
  margin-right: 2px;
}

.rotated-sell-button {
  background-color: #4393c6;
  color: #fff;
}

.horizontal-line {
  border: 1px solid #ccc;
  margin: 20px 0;
}

.footer-link {
  text-decoration: none;
  color: inherit;
  justify-content: center;
}

.footer-link:hover {
  text-decoration: underline;
}

.footerLogo {
  width: 150px;
}

/* Base Styles */
.home_footer_main {
  height: auto;
  background-color: whitesmoke;
  margin-top: 150px;
  padding-block: 25px;
  font-family: "Roboto", "Helvetica", "Arial", sans-serif;
}

.home_footer_logo {
  height: 60px;
  width: 90%;
  margin: auto;
  margin-bottom: 25px;
}

.home_footer_logo > img {
  width: 180px;
}

.home_footer_content_container {
  display: flex;
  justify-content: space-between;
  gap: 10px;
  width: 89%;
  margin: auto;
  margin-bottom: 30px;
}
.home_footer_content_container > div {
  width: 20%;
}

.home_footer_content_container > div > h1,
.home_footer_content_container > div > h1 {
  font-size: 20px;
}

.home_footer_content_container > div > p,
.home_footer_content_container > div > p {
  color: rgb(83, 83, 83);
  font-weight: 600;
}

.home_footer_content_container_one > p:hover,
.home_footer_content_container_two > p:hover,
.home_footer_content_container_three > p:hover {
  text-decoration: underline;
  cursor: pointer;
}

.home_footer_socialIcons_main {
  margin: auto;
  width: 88%;
  margin-bottom: 30px;
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.home_footer_socialIcons_main > h1 {
  margin-right: 20px;
  font-size: 24px;
}

.home_footer_socialIcons_main > div {
  margin-right: 40px;
}
.allSocialIcons {
  display: flex;
  gap: 20px;
}
.socialIcon {
  color: black;
}

.socialIcon:hover {
  color: #186baa; /* Optional: Change color on hover for better UX */
}

.home_footer_allrights_main {
  width: 90%;
  height: auto;
  margin: auto;
  display: grid;
  text-align: center;
  margin-left: 15%;
  grid-template-columns: repeat(2, 1fr);
}

.copyright {
  color: rebeccapurple;
  font-size: 80px;
  margin-right: 100px;
}

.home_footer_allrights_container_one {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 20px;
}

.home_footer_allrights_container_one > h2 {
  font-size: 14px;
  color: rgb(83, 83, 83);
}

.home_footer_allrights_container_two > h2 {
  font-size: 14px;
  color: rgb(83, 83, 83);
}

.home_footer_allrights_container_two {
  text-align: center;
}

.home_footer_disclaimer_main {
  width: 90%;
  margin: auto;
  height: auto;
  margin-bottom: 30px;
}

.home_footer_disclaimer_main > p {
  color: rgb(83, 83, 83);
}

/* Combined Mobile Styles */
@media (max-width: 768px) {
  .rotated-button-container {
    height: auto;
    margin-bottom: -45px;
    transform: none;
  }

  .rotated-heading {
    font-size: 30px;
    margin-top: 20px;
    align-items: flex-start;
    transform: none;
  }

  .rotated-buttons-container {
    align-items: center;
    margin-top: 10px;
    transform: none;
  }

  .rotated-invest-button,
  .rotated-sell-button {
    margin: 5px 0;
    width: 130px;
    padding: 10px;
    font-size: 18px;
  }

  .home_footer_content_container {
    flex-direction: column;
    gap: 30px;
  }
  .home_footer_content_container > div {
    width: auto;
  }

  .home_footer_socialIcons_main {
    text-align: left;
    margin-top: 20px;
  }

  .home_footer_socialIcons_main > h1 {
    margin-right: 0px;
  }

  .home_footer_allrights_main {
    grid-template-columns: 1fr;
    text-align: center;
    margin-top: 10px;
  }

  .home_footer_allrights_container_two {
    text-align: center;
  }

  .home_footer_disclaimer_main {
    text-align: center;
    margin-top: 20px;
  }
}
