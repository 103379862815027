/* Hide the scrollbar */
.scrollbar-hide::-webkit-scrollbar {
    display: none;
}

.scrollbar-hide {
    -ms-overflow-style: none;  /* Internet Explorer 10+ */
    scrollbar-width: none;  /* Firefox */
}

.looping-container {
    overflow: hidden;
    white-space: nowrap;
    width: 100%;
    height: 100px; /* Adjust to fit your heading size */
    display: flex;
    align-items: center;
    background-color: #fff; /* Optional: Background color */
  }
  
  .looping-text {
    display: inline-block;
    font-size: 2rem; /* Adjust font size */
    font-weight: bold;
    text-transform: uppercase;
    color: #2b2828; /* Adjust text color */
    animation: slide 10s linear infinite;
  }
  
  @keyframes slide {
    from {
      transform: translateX(100%);
    }
    to {
      transform: translateX(-100%);
    }
  }
  