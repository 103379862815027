.supremecontainer{
  background-color: black;
  height: 1680px;
}
.stickypart{
  position: -webkit-sticky; /* Safari */
  position: sticky;
  top: 0;
}
.stickyimage{
  width: fit-content;
  height: fit-content;
  justify-content: center;
  position: -webkit-sticky; /* Safari */
  position: sticky;
  left: 500px;
  top: 0;
}
.mycards{
  background-color: black;
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 1400px
}
.text {
  color: white;
  text-align: center;
  /* margin-bottom: 20%; */
}
.main-container {
 position: relative;
 height: 1100px;
}
.left-side {
  flex: 6;
  margin: 10% 0;
  padding-left: 100px;
}
.right-side {
  position: -webkit-sticky; /* Safari */
  position: sticky;
  top: 0;
}

.swiper {
  width: 100%;
  height: 100%;
}
.card-title1,.card-title2,.card-title3,.card-title4{
  color: white;
  font-size: 80px;
  font-family: "Roboto","Helvetica","Arial",sans-serif;
  margin-bottom: -10px;

}
.card-para1,.card-para2,.card-para3,.card-para4{
  color: white;
  font-size: 24px;
  font-family: "Roboto","Helvetica","Arial",sans-serif;
}
.oddnumbercard1{
  position: absolute;
  height: fit-content;
  right: 0;
  padding-right: 240px;
  width: 300px;
  
}
.oddnumbercard3{
  position: absolute;
  margin-top: 40%;
  right: 0;
  padding-right: 240px;
  height: fit-content;
  width: 300px;
}
.evennumbercard2{
  position: absolute;
  margin-top: 18%;
  right: 0;
  padding-right: 240px;
  height: fit-content;
  width: 300px;
}
.evennumbercard4{
  position: absolute;
  margin-top: 65%;
  right: 0;
  padding-right: 240px;
  height: fit-content;
  width: 300px;
}

.swiper-slide {
  text-align: center;
  font-size: 18px;
  background: #fff;
  display: flex;
  justify-content: center;
  align-items: flex-start;
  flex-direction: column;
  height: 50% !important;
  padding-left: 100px;
  width: 70%;
}

.swiper-pagination-vertical.swiper-pagination-bullets,
.swiper-vertical > .swiper-pagination-bullets {
  left: 10px;
}
.swiper-pagination-bullet {
  border: 2px solid black;
  opacity: 0.6;
  background: none;
  width: 6px;
  height: 6px;
}
.swiper-pagination-bullet-active {
  border: none;
  background: grey;
  width: 12px;
  height: 12px;
}

.number {
  padding: 10px;
  margin-bottom: 20px;
  border-radius: 8px;
  color: black;
  border: 2px solid black;
}
.number-focused {
  padding: 10px;
  margin-bottom: 20px;
  border-radius: 8px;
  background-color: #0170dc;
  color: white;
}
.swiper-slide.active {
  opacity: 1;
}
.swiper-slide.inactive {
  opacity: 0.5;
}
.swiper-wrapper {
  top: -27%;
}
#imgbanner{
 background-color: #15242f;
 width: 100%;
 height: 60%;
 margin-top: 70%;
}

.imagecontainer {
  /* height: fit-content;
  position: sticky;
  position: -webkit-sticky;
  top: 0; */
  display: flex;
  align-items: center;
  justify-content: center;
}

.imagecontainer #sliderimage1 {
  /* z-index: 2; */
  position: -webkit-sticky; /* Safari */
  position: sticky;
  top: 0;
}
.imagecontainer #sliderimage2 {
  /* z-index: 2; */
  position: -webkit-sticky; /* Safari */
  position: sticky;
  top: 0;
}
.imagecontainer #sliderimage3 {
  position: -webkit-sticky; /* Safari */
  position: sticky;
  top: 0;
}
.imagecontainer #sliderimage4 {
  position: -webkit-sticky; /* Safari */
  position: sticky;
  top: 0;
}
.imagecontainer #sliderimage5 {
  position: absolute;
  bottom: 17%;
  right: 25%;
}

/* Add this to your CSS to handle small screens */
/* Your existing styles remain unchanged here */

/* Add this to your CSS to handle small screens */

@media (max-width: 600px) {
  .supremecontainer{
    background-color:black;
    height:1375px;
  }
  .imgcntnr{
    background-color: black;
    position: -webkit-sticky; /* Safari */
    position: sticky;
    bottom: 0;
    height: 550px;
  }
  .main-container {
    display: flex;
    flex-direction: column;
  }

  .left-side,
  .right-side {
    flex: 1;
    padding: 0;
    text-align: center;
  }
  .text {
    position: -webkit-sticky; /* Safari */
    position: sticky;
    background-color: black;
    top: 0;
    height: 125px;
    color: white;
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .left-side {
    margin: 0% 0;
    margin-bottom: -80%;
  }

  .right-side {
    z-index: -2; /* Adjust z-index if needed */
  }

  .imagecontainer {
    padding: 20px;
    height: 100%;
    width: 100%;
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column; /* Adjust based on your design */
    top: -5%;
    /* overflow: hidden; */
    margin-bottom: -50%;
  }

  .imagecontainer img {
    /* width: 100%; */
    height: auto; /* Adjust based on your design */
    top: 30%;
    object-fit: cover;
  }

  .slider {
    width: 100%;
  }

  .swiper-slide {
    text-align: center;
    font-size: 18px;
    background: #fff;
    display: flex;
    justify-content: center;
    align-items: flex-start;
    flex-direction: column;
    height: 50% !important;
    padding-left: 0px;
    /* margin-right: 100px; */
    width: auto;
  }

  .swiper-wrapper {
    top: 27%;
  }

  .swiper {
    width: 100%;
    /* height: 100%; */
    height: 100%;
    top: -30px;
  }

  .right-side {
    background-color: white;
  }

  .slider-arrow-smallscreen {
    height: 40px;
    width: 40px;
    margin-top: 300px;
  }
}