/* Import Google font - Poppins */
.containerone {
  display: flex;
  flex-direction: column;
  /* align-items: center; */

  max-width: 475px;
  height: 75px;
  width: 100%;
}

.steps-containerone {
  display: flex;

  gap: 0px;
  margin-left: 15px;
  width: 400px;
  align-items: center;
  justify-content: space-between;
  position: relative;
}

.progress-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 60px;
  text-align: center;
}

.steps-containerone .step-circle {
  z-index: 99;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 20px;
  width: 20px;
  color: #999;
  font-size: 9px;
  font-weight: 500;
  border-radius: 50%;
  background: #fff;
  border: 2px solid #5ece8f;
  transition: all 200ms ease;
  transition-delay: 0s;
}

button {
  background-color: #5ece8f;
}

.steps-containerone .step-circle.active {
  transition-delay: 100ms;
  border-color: #5ece8f;
  background-color: #5ece8f;
  color: white;
}

.steps-containerone .progress-bar-containerone {
  position: absolute;
  height: 4px;
  width: 90%;
  background: #e0e0e0;
  z-index: -1;
  top: 10px;
  left: 19px;
}

.progress-bar-containerone .progress-indicator {
  position: absolute;
  height: 100%;
  width: 0%;
  background: #4070f4;
  transition: all 300ms ease;
}

.action-buttons {
  display: flex;
  gap: 20px;
}

.action-buttons button {
  padding: 8px 25px;
  background: #4070f4;
  border: none;
  border-radius: 8px;
  color: #fff;
  font-size: 16px;
  font-weight: 400;
  cursor: pointer;
  box-shadow: 0 5px 10px rgba(0, 0, 0, 0.05);
  transition: all 200ms linear;
}

.action-buttons button:active {
  transform: scale(0.97);
}

.action-buttons button:disabled {
  background: #87a5f8;
  cursor: not-allowed;
}

@media (max-width: 768px) {
  .containerone {
    max-width: 375px;
    align-items: start;
    justify-content: center;
    flex-direction: row;
  }

  .steps-containerone {
    width: 300px;
    margin-left: 0;
  }
}
