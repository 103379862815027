/* Add new styles for main-section */

/* * {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
} */
body {
  overflow-x: hidden;
  width: 100%;
  height: 100%;
}
.videoTag {
  position: absolute;
  z-index: -1;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  object-fit: cover;
}

.main-section {
  /* display: flex; */
  position: relative;
  height: 90vh;
  overflow: hidden;
  z-index: -1; /* Add z-index to ensure proper stacking */
  background-repeat: no-repeat;
  /* background-position: center; */
  background-size: 100% 100%;
}

.main-section-small {
  /* display: flex; */
  position: relative;
  height: 90vh;
  overflow: hidden;
  display: flex;
  color: rgb(242, 154, 154);
  /* z-index: -1; */
  justify-content: center;
 /* Add z-index to ensure proper stacking */
  background-repeat: no-repeat;
  background-color:#3547f8;
}

.text-container {
  position: absolute;
  top: 35%;
  left: 36%;
  transform: translate(-50%, -50%);
  z-index: -10;
  width: 50%;
  text-align: left;
}
.text-container-small {
  margin-top: -8vh;
  padding-top: 20vh;
  height: 70vh;
  padding-right: 3vh;
  padding-left: 3vh;
  background-color:#4a31ae  ;
  text-align: center;
}

.image-container {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: -1;
  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
}
.image-container-small {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: -1;
  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
}

.sub-section {
  margin-top: -20px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: #ebebeb;
  position: relative;
  /* height: 100px; */
}
.contents {
  display: flex;
  justify-content: space-around;
  align-items: center;
  background-color: white;
  border-radius: 20px;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  padding: 20px;
  position: relative;
  top: -50px;
  gap: 50px;
}

.buttons {
  justify-content: center;
}

.banner {
  text-align: center;
  position: absolute;
  top: 50%;
  bottom: 10%;
  display: flex;
  align-items: center;
  /* padding: 20px; */
}

.bold-text {
  font-weight: bold;
}

.trustpilot-text {
  color: #4393c6;
}

/* Media query for smaller screens */
@media (max-width: 768px) {
  .text-container {
    align-items: flex-start;
    display: flex;
    flex-direction: column;
    top: 34%;
    right: 50%;
    padding: 10%;
  }
  .contents {
    width: fit-content;
    flex-direction: column;
    padding: 3%;
    gap: 1%;
  }
}
