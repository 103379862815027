.container-about {
    max-width: 1080px;
    margin: 0 auto;
    padding: 1rem 3rem;
}

.black {
    background-color: #000;
}

.abt-navbar {
    background: rgba(255, 255, 255, 0.08);
    box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
    backdrop-filter: blur(4.6px);
    -webkit-backdrop-filter: blur(4.6px);
    border: 1px solid rgba(255, 255, 255, 0.31);
}

.container-about p {
    margin-top: 0;
}

.container-about .image-text button {
    font-size: 16px;
    border: none;
    padding: 10px 10px;
    border-radius: 5px;
    cursor: pointer;
    border: 2px solid #0170dc;
    background: transparent;
    color: #0170dc;
}


.about-heading {
    font-family: "Saira Condensed", sans-serif;
    letter-spacing: 0.00938em;
    font-family: "Saira";
    font-style: normal;
    font-weight: bold;
    font-size: 45px;
    line-height: 33px;
    text-align: center;
    color: #fff;
    margin-bottom: 20px;
    margin-top: 8%;
}

.sub-heading {
    font-family: "Arial", sans-serif;
    text-align: center;
    max-width: 30rem;
    margin: 0 auto;
    color: #fff;
    margin-bottom: 1rem;
}

.image-text {
    display: flex;
    gap: 3rem;
    padding-top: 2rem;
}

.image-text img {
    height: 100%;
}

.image-1 {
    border-radius: 1rem;
    object-fit: cover;
    width: 50%;
    object-position: center;
    margin-bottom: 1rem;

}

@media screen and (max-width:480px) {
    .image-1 {
        width: 100%;
    }
}

.content {
    font-family: "Arial", sans-serif;
    font-size: 0.9em;
    line-height: 1.4rem;
    color: #000;
    text-align: justify;
}

.choose-us {
    font-family: "Arial", sans-serif;
    font-size: 14px;
    margin-top: 3rem;
    display: grid;
    grid-template-rows: 1fr 1fr;
    grid-template-columns: 1fr 1fr;
    gap: 2.9rem;
}



.choose-us [class^="item-"] {
    position: relative;
}

.choose-us [class^="item-"] .items-icon {
    position: absolute;
    top: -12%;
    left: 1rem;
    background-color: #fff;
    padding: .5rem;
    border-radius: 1rem;
    border: .1rem solid rgb(0, 89, 89);
    z-index: 0;
    box-shadow: 1px 1px 16px 0px rgba(5, 15, 5, 0.2);
}


.choose-us [class^="item-"] .items-icon:after {
    position: absolute;
    content: "";
    top: -3px;
    height: 35%;
    width: 105%;
    background: #fff;
    left: -2px;
    border-radius: 0rem;
    border-bottom: transparent;
    border: 0.1rem solid #fff;
    border-bottom: 0.1rem solid transparent;
    box-sizing: border-box;
    z-index: -1;
    border-top-left-radius: 1rem;
    border-top-right-radius: 1rem;
}

.choose-us [class^="item-"] .items-icon img {
    z-index: 0;
}


.choose-us [class^="item-"] .choose-text-con {
    background-color: rgb(0 0 0 / 3%);
    border-radius: 1rem;
    padding: 3rem 1rem 1rem;
    line-height: 1.3rem;
}

.choose {
    color: #000;
}

.feautured {
    max-width: 100%;
}

.partner-slider-container .slider-container {
    padding: 0;
}

.partner-slider-container .slick-slider {
    box-shadow: none;
    margin-bottom: 10%;
}

@media screen and (max-width:600px) {
    .content {
        font-size: 12px;
        line-height: 1rem;
    }

    .choose-us {
        grid-template-columns: 1fr;
        margin-top: 5rem;
    }

    .image-text {
        flex-direction: column;
        gap: .5rem;
    }

    .choose {
        margin-top: 4rem;
    }

    .container-about {
        padding: 1rem;
    }

    .partner-slider-container .slick-slider {
        box-shadow: none;
        margin-bottom: 10%;
    }
}