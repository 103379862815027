.signup-container {
  padding-top: 15vh;
  /* padding: 50px; */
  height: 90vh;
  width: 66%;
  margin: auto;
}

.content {
  height: 80vh;
  width: 100vw;
  /* margin-left: 16vw; */
  /* margin-right: 10vw; */
  background: transparent;
  display: flex;
  justify-content: space-between;
  /* align-items: center; */
  gap: 50px;
  /* background-color: #2a86f3; */
}

.left-step {
  /* height: fit-content; */
  margin-top: -8vh;
  width: 65%;
  margin-left: 6%;
  /* background-color: rebeccapurple; */
}

.right-image {
  height: fit-content;
  width: fit-content;
}

.step-image {
  width: 300px;
  height: auto;
}

.signup-step1 {
  display: flex;
  align-items: center;
  gap: 30px;
  margin-top: 40px;
}

.signup-step2 {
  display: flex;
  align-items: center;
  gap: 30px;
  margin-top: 40px;
}

.step-heading1:hover + .step-number {
  background-color: white;
}

.signup-step3 {
  display: flex;
  align-items: center;
  gap: 30px;
  margin-top: 40px;
}

.signup-step4 {
  display: flex;
  align-items: center;
  gap: 30px;
  margin-top: 40px;
}

.step-number {
  height: 25px;
  width: 25px;
  background-color: #2a86f3;
  border-radius: 50%;
  color: white;
  text-align: center;
}

.step-heading1 {
  font-size: 20px;

  color: #bbb;
  cursor: pointer;
}
.left-heading h5 {
  /* color: "rgba(82, 191, 131, 1)",
            fontSize: "24px",
            fontWeight: "600",
            fontFamily: "Plus Jakarta Sans",
            paddingTop: "20px",
            marginTop: "20px", */
  color: rgba(82, 191, 131, 1);
  font-size: 24px;
  font-weight: 600;
  font-family: sans-serif;
  padding-top: 20px;
  margin-top: 20px;
}
.step-heading1:hover {
  margin-bottom: 20px;
  font-size: 28px;
  color: black;
}

.step-heading1:hover + .step-number {
  background-color: blue;
}

.step1-desc {
  margin-left: 52px;
}
.left-desc {
  font-size: 40px;
  font-weight: 700;
  line-height: 38px;
}

@media (max-width: 768px) {
  .signup-container {
    padding-top: 1vh;
    height: 115vh;
    width: 98.7vw;
    margin-top: 50px;
    /* background-color: #2a86f3; */
    margin-left: -22px;
  }
  .content {
    height: 100vh;
    width: 100%;

    margin-left: 0;
    margin-right: 0;
    background: transparent;
    display: flex;
    flex-direction: column;
  }
  .left-heading {
    width: 100%;
  }
  .left-heading .left-desc {
    font-size: 18px;
    margin-top: 15px;
    text-align: center;
    line-height: 18px;
  }
  .left-heading .heading {
    height: 100%;
    width: 100%;

    color: rgba(82, 191, 131, 1);
    font-size: 30px;
    font-weight: 600;
    position: relative;
    margin-right: 20px;
    padding-right: 20px;
    font-family: sans-serif;
    left: 14px;
    margin-top: 20px;
  }
  .left-step {
    width: 100%;
    margin-top: 20px;
  }
  .step-image {
    height: 90%;
  }
  .right-image {
    width: 100%;
    display: flex;
    justify-content: center;
    margin-left: 25px;
    height: auto;
    margin-top: 2px;
  }

  .content {
    height: 90vh;
    width: 80vw;
    margin-left: 10vw;
    margin-right: 10vw;
    gap: 50px;
    background-color: white;
    display: flex;
    flex-direction: column;
  }

  .step-number {
    height: 30px;
    width: 30px;
    border-radius: 50%;
    display: flex;
    text-align: center;
    align-items: center;
    justify-content: center;
  }
  .left-heading h1 {
    font-size: 31px;
    margin-bottom: 15px;
    font-weight: 700;
    text-align: center;
  }
  .left-desc {
    font-size: 14px;
    font-family: "Plus Jakarta Sans";
    text-align: center;
    line-height: 14px;
  }
}
