.amenities_main {
    margin: 20px;
    font-family: Arial, sans-serif;
    /* border: solid red; */
    width:300px ;
    margin: auto;
    text-align: center;
    padding: 50px;
    border-radius: 10px;
    box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
  }
  
  .amenities_container {
    display: flex;
    margin-bottom: 10px;
  }
  
  .amenities_container label {
    margin-left: 10px;
    color: #333;
    font-size: 16px;
    
  }
  
  .amenities_container input[type="checkbox"] {
    margin-right: 5px;
    width: 20px;
    height: 20px;
    appearance: none;
    border: 2px solid #666;
    border-radius: 4px;
    cursor: pointer;
    transition: all 0.3s ease;
    margin-bottom: -5px;
  }
  
  .amenities_container input[type="checkbox"]:checked {
    background-color: #4CAF50;
    border-color: #4CAF50;
  }