.signup-name-container {
    max-width: 960px;
    margin: auto;
    height: 100vh;
}

.signup-name-container form {
    font-family: system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
    height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    padding: 0 2rem;
}

.signup-name-container form input {
    width: 100%;
    outline: none;
    border: none;
    border-bottom: 2px solid blue;
    border-radius: unset;
    margin: 1rem 0;
    padding: 10px 0;
    font-size: 20px;
}

::placeholder {
    color: #9d9de1;
    opacity: .7;
}

.signup-name-container form button {
    background: blue;
    font-size: 20px;
}

.signup-name-container label {
    font-size: 20px;
}
