.location_Container{
    border: 2px solid black;
    border-radius: 10px;
    margin: 20px;
    padding: 20px;
    width: 400px;
    margin: auto;
}

.location_Container input:focus-within {
    border: 1px solid #0170dc;
  }