.submissions-wrapper {
    width: 100%;
    min-height: 100vh;
    background-color: #f8f9fa;
    padding: 0;
  }
  
  .header-container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 20px;
    background-color: white;
    border-bottom: 1px solid #dee2e6;
  }
  
  .header-container h1 {
    margin: 0;
    font-size: 24px;
    color: #333;
    font-weight: 500;
  }
  
  .refresh-button {
    background-color: #4CAF50;
    border: none;
    padding: 8px 16px;
    border-radius: 4px;
  }
  
  .refresh-button:hover {
    background-color: #45a049;
  }
  
  .table-container {
    background-color: white;
  }
  
  .submissions-table {
    margin-bottom: 0;
    width: 100%;
    table-layout: fixed;
  }
  
  .submissions-table thead {
    background-color: white;
  }
  
  .submissions-table th,
  .submissions-table td {
    padding: 16px;
    vertical-align: middle;
    border-bottom: 1px solid #dee2e6;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
  
  .submissions-table th {
    color: #333;
    font-weight: 500;
    background-color: #fff;
    white-space: nowrap;
    padding: 16px;
    text-align: left;
  }
  
  /* Column widths */
  .col-name {
    width: 15%;
  }
  
  .col-email {
    width: 20%;
  }
  
  .col-mobile {
    width: 12%;
  }
  
  .col-message {
    width: 23%;
    min-width: 120px; /* Ensure minimum width for the header */
    white-space: normal !important;
    word-break: break-word;
  }
  
  .col-status {
    width: 10%;
  }
  
  .col-date {
    width: 10%;
  }
  
  .col-action {
    width: 10%;
  }
  
  .status-select {
    width: 100%;
    padding: 6px 12px;
    border: 1px solid #dee2e6;
    border-radius: 4px;
    background-color: white;
  }
  
  .loading-container {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
  }
  
  .error-container {
    padding: 20px;
  }
  
  /* Hover effect */
  .submissions-table tbody tr:hover {
    background-color: #f8f9fa;
  }
  
  @media (max-width: 1200px) {
    .submissions-table {
      table-layout: auto;
    }
    
    .col-message {
      width: auto;
    }
  }
  
  @media (max-width: 768px) {
    .header-container {
      padding: 16px;
    }
    
    .submissions-table {
      font-size: 14px;
    }
    
    .submissions-table td,
    .submissions-table th {
      padding: 12px 8px;
    }
  }
  
  