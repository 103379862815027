div {
  color: black;
}

.hero-heading {
  display: flex;
  flex-direction: column;
}

.hero-new-container {
  /* padding-right: 35px; */
}

.hero-heading {
  margin-top: 60px;
}

/* .hero-heading h3 {
  font-size: 50px;
  font-weight: 700;
  line-height: 60px;
} */

.left-div h3 {
  margin-bottom: 30px;
}

.main-heading-hero {
  height: 60vh;
  width: 100%;
  background-color: white;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 3rem;
  justify-content: center;
}

.main-heading h1 {
  color: #000;
  font-weight: 600;
  font-size: 3rem;
  text-align: center;
  line-height: 4rem;
}

.main-button {
  width: 200px;
  height: 7vh;
  margin-top: 20px;
  margin-bottom: 10px;
  border-radius: 10px;
  background-color: #0170dc;
  box-shadow: 1px 2px 5px grey;
  border: none;
  font-size: 16px;
  font-weight: bold;
  letter-spacing: 1px;
}

.live-property {
  width: 100%;
  padding-top: 20px;
  height: 350px;
  margin-left: auto;
  margin-right: auto;
}

.inner-box {
  height: 100%;
  width: 60%;
  margin-top: 10px;
  margin-right: auto;
  margin-left: auto;
  border: 2px solid grey;
  border-radius: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.left-div {
  height: 100%;
  width: 100%;
  /* margin-left: 20px; */
  background-color: white;
  display: flex;
  /* flex: 1; */
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 4px;
  color: black;
  border-radius: 30px;
}

.left-div h3 {
  color: black;
}

.right-div {
  flex: 1;
  height: 115%;
  margin-top: -1%;
  margin-right: 28px;
  width: 35%;
  border-radius: 10px;
  background-color: #2f75a6;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 20px;
}

.cal-image {
  height: 80%;
  width: 80%;
  margin-top: -20px;
}

.property-image {
  height: 76%;
  width: 52%;
  object-fit: contain;
  background-color: #fff;
  border-radius: 20px;
}

@media (max-width: 768px) {
  .container {
    height: 150vh;
  }

  .inner-box {
    flex-direction: column;
    height: auto;
    gap: 0px;
  }

  .right-div {
    height: 40vh;
    padding-left: 4vh;
    padding-right: 4vh;
    margin-left: 25px;
  }

  .right-div .property-image {
    height: 40%;
    background-color: #2f75a6;
    border-radius: 10px;
  }

  .left-div,
  .right-div {
    flex: none;
    width: 100%;
  }
  .left-div {
    height: auto;
    background-color: white;
    display: flex;
    /* flex: 1; */
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 4px;
    color: black;
    border-radius: 30px;
  }
  .live-property {
    height: auto;
  }

  .hero-heading {
    margin-top: 40px;
  }
}

.property_card_container {
  width: 80%;
  height: auto;
  /* background-color: red; */
  display: flex;
  gap: 20px;
  justify-content: center;
  position: relative;
  z-index: 10;
}

.blue_container {
  width: 100%;
  height: 120%;
  background-color: #2382ea;
  border-radius: 20px;
  display: flex;
  /* margin-top: -20px; */
  flex-direction: column;
  align-items: center;
  justify-content: start;
  gap: 10px;
  padding: 25px 25px 65px 25px;
  scale: 0.8;
}

.property_heading_label {
  font-size: 20px;
  font-weight: 600;
  color: white;
  margin-bottom: 15px;
  margin-top: 1px;
  margin-top: 5px;
}

.property_card {
  width: 250px;
  height: fit-content;
  margin-top: 40px;
  border-radius: 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 10px;
  margin-top: 15px;
}

.invest_in_real_estate_parent {
  display: flex;
}

.space {
  margin-right: 9px;
}

.invest_in {
  color: var(--heading-color);
  font-weight: var(--heading-font-wight);
  letter-spacing: var(--heading-letter-spacing);
  line-height: var(--heading-line-height);
  font-size: 4rem;
}

.invest_in_real_estate {
  color: var(--heading-color);
  font-weight: var(--heading-font-wight);
  letter-spacing: var(--heading-letter-spacing);
  line-height: var(--heading-line-height);
  font-size: 4rem;
}

.with_just {
  color: var(--heading-color);
  font-weight: var(--heading-font-wight);
  letter-spacing: var(--heading-letter-spacing);
  line-height: var(--heading-line-height);
  font-size: 3rem;
}

/* responsiveness */

@media (max-width: 1024px) {
  .inner-box {
    width: 70%;
    gap: 5px;
    padding-top: 10px;
  }

  .left-div {
    margin-top: 50px;
    width: 100%;
    height: fit-content;
  }

  .blue_container {
    width: 70%;
    height: 105%;
    padding: 10px 0;
    transform: translateY(100px);
  }
}

@media (max-width: 768px) {
  .inner-box {
    width: 70%;
    gap: 5px;
    padding-top: 18px;
    flex-direction: column;
  }

  .left-div {
    margin-top: 50px;
    width: 100%;
    height: fit-content;
  }

  .invest_in_real_estate_parent {
    display: flex;
    flex-direction: column;
    align-items: center;
    flex-wrap: nowrap;
  }

  .invest_in {
    font-size: 2.2rem;
    font-weight: 800;
    color: rgb(33, 32, 32);
  }

  .invest_in_real_estate {
    text-wrap: nowrap;
    font-size: 2.5rem;
    font-weight: 750;
    color: rgb(33, 32, 32);
  }

  .with_just {
    font-size: 1.9rem;
    font-weight: 750;
    color: rgb(28, 27, 27);
  }

  .main-button {
    width: 150px;
    /* background-color: #535DF6; */
  }
}

@media (max-width: 450px) {
  .invest_in_real_estate {
    text-align: center;
    text-wrap: nowrap;
  }

  .inner-box {
    width: 80%;
    gap: 5px;
  }

  .invest_in_real_estate_parent {
    display: flex;
    flex-direction: column;
    align-items: center;
    flex-wrap: nowrap;
  }

  .space {
    margin: none;
  }

  .left-div {
    margin-top: 10px;
    width: 100%;
    height: fit-content;
    margin-top: 0px;
  }

  .property_card_container {
    flex-direction: column;
    gap: 20px;
    margin-left: 50px;
    margin-right: 50px;
    width: 270px;
    align-items: center;
    justify-content: center;
    margin-top: -40px;
  }

  .blue_container {
    width: 125%;
    height: 120%;
    scale: 1;
    padding: 10px 16px;
    transform: translateY(100px);
  }
}
