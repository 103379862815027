.progress-tag {
    background-color: #63e663;
    border-radius: 1rem;
    width: max-content;
    padding: 0 10px;
    color: #fff;
}

.yellowTheme{
    background-color: #d7e663;
    border-radius: 1rem;
    width: max-content;
    padding: 0 10px;
    color: #fff;
}
.GreenTheme{
    background-color: #63e663;
    border-radius: 1rem;
    width: max-content;
    padding: 0 10px;
    color: #fff;
}