.second-page {
  height: 100vh;
  width: 80vw;
  background-color: red;
}

.sticky-div {
  max-width: 500px;
  position: sticky;
  /* background-color: antiquewhite; */
  top: 10px;
  /* height: 60vh; */
  height: fit-content;
  background-color: white;
  margin-top: 80px;
  width: 430px;
}

.content-div {
  margin-top: 0px;
  display: flex;
  /* alignItems: "center"; */
  justify-content: center;
  /* padding: "1rem"; */
  gap: 6.3rem;
  height: fit-content;
}

.logo_div {
  width: 100%;
  display: flex;
  align-items: center;
  margin-top: 20px;
  justify-content: space-between;
  background-color: white;
  gap: 20px;
}

.logo_div > img {
  width: 30%;
  height: auto;
  /* flex: 1 1 0; */
  /* width: 100%; */
  margin: 10px 2px;
  object-fit: contain;
  /* flex-grow: 1; */
}

@media (max-width: 768px) {
  .content-div {
    display: flex;
    flex-direction: column;
    /* position: sticky; */
    align-items: center;
    justify-content: center;
    /* margin-right: 30px; */
    width: 90%;
    /* margin: auto; */
    margin-right: auto;
    margin-left: auto;
    /* margin-top: -150px; */
    gap: 10px;
  }

  .sticky-div {
    margin-top: 100px;
    position: relative;
    height: 45vh;
    /* margin-left: 20px; */
    background-color: white;
    width: 95%;
    padding: 0px 20px;
  }
}
